import React from 'react';
import { Anchor } from 'antd';
import { useTranslation } from 'react-i18next';

const { Link } = Anchor;

function TermMenu() {
  const { t } = useTranslation();

  const handleGetContainer = () => document.querySelector('#term-right');

  return (
    <Anchor
      style={{ width: 300, offsetTop: 64, background: 'unset', height: '100%' }}
      getContainer={handleGetContainer}
      showInkInFixed
      affix
    >
      <Link href="#preamble" title={t('term:preamble')} />
      <Link
        href="#chapter-1-general-rules"
        title={t('term:chapter-1-general-rules')}
      >
        <Link
          href="#section-1-definition"
          title={t('term:section-1-definition')}
        />
        <Link
          href="#section-2-service-content"
          title={t('term:section-2-service-content')}
        />
        <Link
          href="#section-3-application-and-modification-of-this-agreement"
          title={t(
            'term:section-3-application-and-modification-of-this-agreement'
          )}
        />
      </Link>
      <Link
        href="#chapter-2-service-usage"
        title={t('term:chapter-2-service-usage')}
      >
        <Link
          href="#section-4-account-application"
          title={t('term:section-4-account-application')}
        />
        <Link
          href="#section-5-disapproval-of-account-application"
          title={t('term:section-5-disapproval-of-account-application')}
        />
        <Link
          href="#section-6-change-of-account-content"
          title={t('term:section-6-change-of-account-content')}
        />
        <Link
          href="#section-7-management-of-login-account"
          title={t('term:section-7-management-of-login-account')}
        />
        <Link
          href="#section-8-suspension-of-account-or-termination-of-usage-contract"
          title={t(
            'term:section-8-suspension-of-account-or-termination-of-usage-contract'
          )}
        />
        <Link
          href="#section-9-cancellation-of-user-agreement"
          title={t('term:section-9-cancellation-of-user-agreement')}
        />
        <Link
          href="#section-10-notice-from-us"
          title={t('term:section-10-notice-from-us')}
        />
        <Link
          href="#section-11-service-usage-fees"
          title={t('term:section-11-service-usage-fees')}
        />
        <Link
          href="#section-12-start-of-using-the-service"
          title={t('term:section-12-start-of-using-the-service')}
        />
        <Link
          href="#section-13-suspension-of-paid-services"
          title={t('term:section-13-suspension-of-paid-services')}
        />
        <Link
          href="#section-14-prohibited-matters"
          title={t('term:section-14-prohibited-matters')}
        />
      </Link>
      <Link
        href="#chapter-3-other-disclaimers"
        title={t('term:chapter-3-other-disclaimers')}
      >
        <Link
          href="#section-15-liability-for-providing-this-service"
          title={t('term:section-15-liability-for-providing-this-service')}
        />
        <Link
          href="#section-16-protection-of-personal-information-and-legal-compliance"
          title={t(
            'term:section-16-protection-of-personal-information-and-legal-compliance'
          )}
        />
        <Link href="#section-17-damages" title={t('term:section-17-damages')} />
        <Link
          href="#section-18-disclaimer"
          title={t('term:section-18-disclaimer')}
        />
        <Link
          href="#section-19-governing-law"
          title={t('term:section-19-governing-law')}
        />
        <Link
          href="#section-20-consultation-and-court-of-jurisdiction"
          title={t('term:section-20-consultation-and-court-of-jurisdiction')}
        />
      </Link>
    </Anchor>
  );
}

export default React.memo(TermMenu);
