import React from 'react';
import { Typography } from 'antd';
import { Trans } from 'gatsby-plugin-react-i18next';

const { Title, Paragraph } = Typography;

function Chapter3() {
  return (
    <>
      <Title level={2} id="chapter-3-other-disclaimers">
        <Trans ns="term" i18nKey="chapter-3-other-disclaimers" />
      </Title>
      <Paragraph>
        <Title level={3} id="section-15-liability-for-providing-this-service">
          <Trans
            ns="term"
            i18nKey="section-15-liability-for-providing-this-service"
          />
        </Title>
        <ol>
          <li>
            <Trans
              ns="term"
              i18nKey="we-will-endeavor-to-maintain-and-operate-the-equipment-for-this-service-so-that-this-service-can-be-provided-smoothly-however-the-user-acknowledges-that-this-service-may-not-be-available-due-to-unforeseen-circumstances"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-shall-not-be-liable-for-any-damages-caused-by-the-temporary-inability-of-users-to-use-this-service"
            />
          </li>
        </ol>
      </Paragraph>
      <Paragraph>
        <Title
          level={3}
          id="section-16-protection-of-personal-information-and-legal-compliance"
        >
          <Trans
            ns="term"
            i18nKey="section-16-protection-of-personal-information-and-legal-compliance"
          />
        </Title>
        <ol>
          <li>
            <Trans
              ns="term"
              i18nKey="in-principle-we-will-not-disclose-or-provide-personal-information-to-anyone-other-than-the-user-himself-and-will-not-use-it-beyond-the-range-necessary-for-providing-this-service-the-user-consents-to-the-handling-of-the-personal-information-of-the-user-acquired-by-the-company-in-accordance-with-the-privacy-policy-separately-set-by-the-company"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-reserve-all-rights-regarding-information-such-as-the-terminal-test-of-the-user-the-history-of-remote-operation-usage-and-the-record-of-various-processing-results-that-accompany-them-which-occurs-as-a-result-of-the-use-of-this-service-by-the-user-i-shall-belong"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-will-use-the-information-obtained-in-accordance-with-the-preceding-paragraph-for-the-purpose-of-improving-this-service-and-other-services-operated-by-our-company-as-well-as-using-it-for-statistics-and-analysis-in-the-form-that-personal-information-and-user-information-are-unknown-person-can-use-it"
            />
          </li>
        </ol>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-17-damages">
          <Trans ns="term" i18nKey="section-17-damages" />
        </Title>
        <ol>
          <li>
            <Trans
              ns="term"
              i18nKey="our-company-shall-not-be-liable-to-the-user-for-any-damage-caused-by-the-use-of-this-service-and-shall-not-be-liable-for-compensation-for-such-damage"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-will-delete-the-registered-or-posted-information-of-the-user-suspend-or-delete-the-qualification-of-the-user-or-stop-suspend-cancel-etc-of-the-service-we-assume-no-responsibility-whatsoever"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="if-a-user-causes-damage-to-other-users-of-this-service-or-a-third-party-by-using-this-service-the-user-shall-resolve-it-at-his-own-responsibility-and-expense-and-shall-not-damage-us-i-will-in-this-case-because-other-users-of-the-service-or-a-third-party-have-pursued-liability-to-us-payment-of-damages-settlement-or-other-damages-or-litigation-expenses-including-attorneys-fees-and-litigation-procedure-expenses-etc-the-user-shall-immediately-compensate-for-the-damage-paid-by-the-company-and-the-cost-spent"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="if-the-operation-of-this-service-is-affected-by-the-mass-delivery-of-e-mails-by-the-user-we-may-claim-criminal-charges-or-damages-due-to-the-obstruction-of-our-business"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="if-the-user-damages-us-due-to-an-act-that-violates-this-agreement-or-an-illegal-or-illegal-act-we-shall-be-able-to-make-a-claim-for-damages-to-the-user"
            />
          </li>
        </ol>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-18-disclaimer">
          <Trans ns="term" i18nKey="section-18-disclaimer" />
        </Title>
        <ol>
          <li>
            <Trans
              ns="term"
              i18nKey="even-if-there-is-damage-to-the-user-in-connection-with-the-use-of-this-service-such-as-delay-change-suspension-or-abolition-of-provision-of-this-service-loss-or-loss-of-information-etc-provided-through-this-service-does-not-take-any-responsibility"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="our-company-shall-not-be-liable-for-any-damage-caused-to-the-user-due-to-defects-failures-malfunctions-or-malfunctions-of-the-device-software-or-communication-network-used-for-using-this-service"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-do-not-guarantee-the-accuracy-safety-legality-suitability-purposefulness-etc-of-the-content-of-the-test-results-obtained-by-using-this-service-our-company-will-not-be-liable-for-any-damage-incurred-by-the-user-based-on-this-test-result"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="our-company-shall-endeavor-to-enable-users-to-use-this-service-for-24-hours-the-user-shall-use-this-service-after-consenting-to-the-fact-that-our-company-does-not-guarantee-the-occupied-time-of-the-terminal-operating-environment-communication-environment-etc"
            />
          </li>
        </ol>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-19-governing-law">
          <Trans ns="term" i18nKey="section-19-governing-law" />
        </Title>
        <Trans
          ns="term"
          i18nKey="regarding-the-establishment-effect-performance-and-interpretation-of-this-agreement-the-laws-of-japan-shall-apply"
        />
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-20-consultation-and-court-of-jurisdiction">
          <Trans
            ns="term"
            i18nKey="section-20-consultation-and-court-of-jurisdiction"
          />
        </Title>
        <ol>
          <li>
            <Trans
              ns="term"
              i18nKey="in-case-of-doubt-regarding-the-interpretation-of-these-terms-we-shall-be-able-to-determine-the-interpretation-within-a-reasonable-range"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="for-all-disputes-regarding-this-service-between-our-company-and-the-user-the-tokyo-summary-court-or-the-tokyo-district-court-in-japan-shall-be-the-exclusive-agreement-jurisdictional-court-of-the-first-instance-depending-on-the-amount-of-the-complaint"
            />
          </li>
        </ol>
        <Trans ns="term" i18nKey="this-agreement-will-come-into-effect-on" />
      </Paragraph>
    </>
  );
}

export default React.memo(Chapter3);
