import React from 'react';
import { Select } from 'antd';
import { Link, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';
import { LANGUAGES } from 'data/languages';
import PropTypes from 'prop-types';
import { locale } from 'moment';
import { useStaticQuery, graphql } from 'gatsby';

const { Option } = Select;

export default function Header(props) {
  const images = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo/moppium_horizontal_375x100.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { title } = props;
  const { changeLanguage } = useI18next();
  const { language } = React.useContext(I18nextContext);

  const handleChangeLanguage = (lang) => {
    changeLanguage(lang);
    locale(lang);
  };

  return (
    <header {...props}>
      <Link to="/">
        <i
          className="logo"
          style={{
            background: `url(${images?.logo?.childImageSharp?.fluid?.src}) no-repeat 0 50%`,
            backgroundSize: '150px 40px',
          }}
        />
        <span>{title}</span>
      </Link>
      <div className="button">
        <Select
          size="small"
          value={language}
          onChange={handleChangeLanguage}
          style={{ width: 90 }}
        >
          {LANGUAGES.map(({ key, value }) => (
            <Option key={key} value={key}>
              {value}
            </Option>
          ))}
        </Select>
      </div>
      {/* <div
        style={{
          marginTop: 0,
          marginBottom: '0.5em',
        }}
      >
        <Select
          size="small"
          defaultValue={language}
          onChange={handleChangeLanguage}
          style={{ width: 90 }}
        >
          {LANGUAGES.map(({ key, value }) => (
            <Option key={key} value={key}>
              {value}
            </Option>
          ))}
        </Select>
      </div> */}
    </header>
  );
}

// import React from 'react';
// import { Select } from 'antd';
// import { Link, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';
// import { LANGUAGES } from 'data/languages';
// import PropTypes from 'prop-types';

// const logo = `${process.env.GATSBY_S3_STORAGE_URL}/public/1.0/logo/moppium_horizontal_375x100.png`;

// const { Option } = Select;

// export default function Header({ title }) {
//   const { changeLanguage } = useI18next();
//   const { language } = React.useContext(I18nextContext);

//   const handleChangeLanguage = (lang) => {
//     changeLanguage(lang);
//   };

//   return (
//     <header>
//       <div className="header-content">
//         <h1>
//           <Link
//             to="/"
//             style={{
//               background: `url(${logo}) no-repeat 0 50%`,
//               backgroundSize: '150px 40px',
//             }}
//           >
//             <span>{title}</span>
//           </Link>
//         </h1>
//         <div
//           style={{
//             marginTop: 0,
//             marginBottom: '0.5em',
//           }}
//         >
//           <Select
//             size="small"
//             defaultValue={language}
//             onChange={handleChangeLanguage}
//             style={{ width: 90 }}
//           >
//             {LANGUAGES.map(({ key, value }) => (
//               <Option key={key} value={key}>
//                 {value}
//               </Option>
//             ))}
//           </Select>
//         </div>
//       </div>
//     </header>
//   );
// }

Header.propTypes = {
  title: PropTypes.string,
};
