import React from 'react';
import { Typography } from 'antd';
import { Trans } from 'gatsby-plugin-react-i18next';
import PreviewCompatibleImage from 'components/PreviewCompatibleImage';
import { useStaticQuery, graphql } from 'gatsby';

const { Title, Paragraph } = Typography;

function TermHeader() {
  const data = useStaticQuery(graphql`
    query {
      term: file(relativePath: { eq: "term.svg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <Typography>
      <Paragraph style={{ textAlign: 'center' }}>
        <PreviewCompatibleImage
          imageInfo={data.term}
          style={{ height: '200px' }}
        />
        <Title style={{ marginTop: 30 }}>
          <Trans ns="term" i18nKey="terms-of-service" />
        </Title>
      </Paragraph>
      <Paragraph>
        <Trans
          ns="term"
          i18nKey="moppium-will-be-provided-to-corporations-and-organizations-that-have-agreed-to-the-terms-set-forth-below-by-ordering-moppium-you-agree-to-be-bound-by-these-terms-of-use"
        />
      </Paragraph>
      <Title level={2} id="preamble">
        <Trans ns="term" i18nKey="preamble" />
      </Title>
      <Paragraph>
        <Trans
          ns="term"
          i18nKey="the-moppium-terms-of-service-establishes-the-relationship-between-moppium-users-and-us-this-agreement-applies-to-all-relationships-that-occur-in-connection-with-the-use-of-this-service"
        />
      </Paragraph>
    </Typography>
  );
}

export default React.memo(TermHeader);
