import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const PreviewCompatibleImage = ({ imageInfo, style }) => {
  const imageStyle = { borderRadius: '5px', ...style };
  const { alt = '', childImageSharp, image, extension, publicURL } = imageInfo;

  // svg support
  if (!childImageSharp && extension === 'svg') {
    return <img style={imageStyle} src={publicURL} alt={alt} />;
  }

  if (!!image && !!image.childImageSharp) {
    return (
      <Img style={imageStyle} fluid={image?.childImageSharp?.fluid} alt={alt} />
    );
  }

  if (childImageSharp) {
    return <Img style={imageStyle} fluid={childImageSharp.fluid} alt={alt} />;
  }

  if (!!image && typeof image === 'string')
    return <img style={imageStyle} src={image} alt={alt} />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
    publicURL: PropTypes.string,
    extension: PropTypes.string,
  }).isRequired,
  style: PropTypes.object,
};

export default PreviewCompatibleImage;
