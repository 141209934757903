import React from 'react';
import { Typography } from 'antd';
import { Trans } from 'gatsby-plugin-react-i18next';

const { Title, Paragraph } = Typography;

function Chapter2() {
  return (
    <>
      <Title level={2} id="chapter-2-service-usage">
        <Trans ns="term" i18nKey="chapter-2-service-usage" />
      </Title>
      <Paragraph>
        <Title level={3} id="section-4-account-application">
          <Trans ns="term" i18nKey="section-4-account-application" />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="after-accepting-this-agreement-the-user-shall-apply-for-the-use-of-this-service-based-on-the-procedure-specified-by-our-company"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="a-term-of-service-hereinafter-referred-to-as-term-of-service-containing-the-provisions-set-forth-in-these-terms-shall-be-established-when-we-accept-the-application-for-the-account"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-may-ask-users-to-submit-materials-for-confirming-the-whereabouts-of-companies-or-individuals-regarding-the-content-of-application-for-accounts-if-you-do-not-submit-these-materials-we-may-not-accept-your-application-for-an-account"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-5-disapproval-of-account-application">
          <Trans
            ns="term"
            i18nKey="section-5-disapproval-of-account-application"
          />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="if-a-user-applies-for-an-account-and-this-user-falls-under-any-of-the-following-items-we-may-not-accept-this-application"
            />
            <ol>
              <li>
                <Trans
                  ns="term"
                  i18nKey="1-acts-that-infringe-other-users-of-this-service-third-parties-or-our-intellectual-property-rights-copyright-design-right-patent-right-utility-model-right-trademark-right-know-how-etc-or-actions-that-may-result"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="2-if-you-do-not-apply-for-an-account-as-specified-by-us"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="3-when-false-information-is-registered-when-registering-an-account"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="4-if-we-determine-that-using-this-service-may-interfere-with-the-operation-of-this-service-or-the-system-for-that-purpose"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="5-when-we-determine-that-the-application-for-an-account-may-be-the-purpose-of-investigating-our-business-content"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="6-when-our-company-judges-that-it-is-the-same-industry"
                />
              </li>
              <li>
                <Trans ns="term" i18nKey="7-if-you-are-not-a-legal-person" />
              </li>
            </ol>
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-shall-not-be-liable-for-any-damages-caused-to-the-user-by-not-accepting-the-application-for-the-account"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-6-change-of-account-content">
          <Trans ns="term" i18nKey="section-6-change-of-account-content" />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="if-there-is-a-change-in-the-information-registered-when-applying-for-an-account-the-user-shall-immediately-make-that-change-by-the-method-specified-by-us"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-shall-not-be-liable-for-any-damages-caused-to-the-user-due-to-the-failure-to-change-the-previous-paragraph"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-7-management-of-login-account">
          <Trans ns="term" i18nKey="section-7-management-of-login-account" />
        </Title>
        <Trans
          ns="term"
          i18nKey="the-login-id-and-password-related-to-the-account-shall-be-managed-responsibly-by-the-user-himself-such-as-changing-them-regularly-so-that-they-are-not-known-to-others-if-we-confirm-by-a-prescribed-method-that-the-entered-login-id-and-password-match-the-registered-one-we-will-consider-that-it-has-been-used-by-the-user-and-due-to-plagiarism-unauthorized-use-or-other-circumstances-our-company-shall-not-be-liable-for-any-damages-caused-by-it-even-if-it-is-used-by-a-person-other-than-the-user"
        />
      </Paragraph>
      <Paragraph>
        <Title
          level={3}
          id="section-8-suspension-of-account-or-termination-of-usage-contract"
        >
          <Trans
            ns="term"
            i18nKey="section-8-suspension-of-account-or-termination-of-usage-contract"
          />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="if-the-user-falls-under-any-of-the-following-items-regardless-of-the-reason-we-immediately-stop-providing-this-service-to-this-user-suspend-the-account-of-the-user-or-use-the-contract-cancellation-shall-be-possible-in-addition-in-this-case-if-this-user-causes-damage-to-us-we-can-claim-damages-to-this-user"
            />
            <ol>
              <li>
                <Trans
                  ns="term"
                  i18nKey="1-when-it-is-found-that-false-information-was-registered-during-account-registration"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="2-when-an-act-that-violates-these-terms"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="3-when-we-judge-that-there-is-a-possibility-that-there-is-or-may-be-an-act-that-conflicts-with-the-contents-stipulated-in-section-14-prohibitions-of-these-terms"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="4-when-we-judge-that-it-is-the-same-user-who-has-violated-this-agreement-and-the-terms-of-use-of-other-services-provided-by-us"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="5-when-we-determine-that-using-this-service-may-interfere-with-the-operation-of-this-service-or-system"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="6-when-we-judge-that-the-use-of-this-service-may-be-for-the-purpose-of-investigating-our-business-content"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="7-in-case-of-giving-any-disadvantage-to-us-due-to-intention-or-negligence"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="8-when-the-information-on-this-service-has-been-tampered-with"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="9-if-the-account-and-password-are-used-improperly"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="10-when-using-this-service-by-a-user-of-another-service-or-a-third-party-without-our-permission"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="11-in-addition-when-we-judge-that-it-is-inappropriate-as-a-user-of-this-service"
                />
              </li>
              <li>
                <Trans
                  ns="term"
                  i18nKey="12-if-there-is-no-transfer-of-the-service-usage-fee-by-the-deadline-this-service-will-be-generated-by-discontinuing-the-provision-of-the-service-to-the-user-suspending-the-account-or-canceling-the-usage-contract-based-on-the-preceding-paragraph-we-do-not-take-any-responsibility-for-damages"
                />
              </li>
            </ol>
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="based-on-the-preceding-paragraph-we-will-not-be-liable-for-any-damages-caused-to-this-user-by-discontinuing-the-provision-of-this-service-to-the-user-suspending-the-account-or-canceling-the-usage-contract"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-9-cancellation-of-user-agreement">
          <Trans ns="term" i18nKey="section-9-cancellation-of-user-agreement" />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="the-user-shall-notify-the-cancellation-by-the-method-specified-by-our-company"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="the-terms-of-service-shall-be-canceled-on-the-day-when-we-confirm-the-notification-of-cancellation-from-the-user-based-on-the-provisions-of-the-preceding-paragraph"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="on-the-cancellation-date-any-liability-of-the-user-that-occurred-during-the-effective-period-of-the-usage-contract-shall-not-be-extinguished-until-the-obligation-is-fulfilled-even-after-the-cancellation-of-the-terms-of-service"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="all-fees-paid-to-us-by-the-user-during-the-terms-of-service-are-non-refundable-and-the-user-acknowledges-this"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-will-not-back-up-the-data-of-this-user-after-the-user-has-notified-that-the-terms-of-service-have-been-cancelled-backup-of-data-after-notification-of-cancellation-of-terms-of-service-shall-be-done-at-the-users-own-risk-and-we-shall-not-be-liable-for-any-damage-caused-to-the-user-regarding-data-backup-etc"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-10-notice-from-us">
          <Trans ns="term" i18nKey="section-10-notice-from-us" />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="if-we-determine-that-it-is-necessary-to-notify-the-user-etc-we-will-notify-from-time-to-time-by-any-method-we-think-is-appropriate-such-as-posting-by-e-mail-in-writing-or-on-our-website"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="when-we-give-notices-on-the-web-page-of-this-service-it-is-assumed-that-the-notice-etc-arrived-at-the-user-on-the-day-when-the-notice-etc-was-posted-on-the-web-page-in-addition-when-notifications-etc-are-sent-by-e-mail-it-is-considered-that-the-notification-etc-has-arrived-at-the-user-when-our-company-sends-the-e-mail-to-the-user"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-11-service-usage-fees">
          <Trans ns="term" i18nKey="section-11-service-usage-fees" />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="when-using-the-paid-services-of-this-service-the-user-shall-pay-the-usage-fee-to-us-the-user-shall-pay-this-usage-fee-by-the-payment-method-specified-by-our-company"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-will-not-calculate-the-prorated-amount-when-billing-the-usage-fee-to-the-user"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-will-not-refund-the-prepaid-usage-fee-even-if-the-usage-contract-ends-during-the-usage-period-of-the-paid-service"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-12-start-of-using-the-service">
          <Trans ns="term" i18nKey="section-12-start-of-using-the-service" />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="after-signing-the-terms-of-service-the-user-selects-the-type-of-service-that-the-user-desires-to-use-from-this-service-and-uses-it"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="in-the-case-of-the-preceding-paragraph-if-the-service-that-the-user-desires-to-use-is-a-paid-service-the-user-shall-be-able-to-use-this-paid-service-after-confirming-the-payment-from-the-user"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="our-company-shall-determine-the-usage-period-of-the-paid-service-corresponding-to-the-usage-fee-and-the-user-can-use-the-paid-service-only-for-the-period-according-to-the-usage-fee-paid-to-us"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-13-suspension-of-paid-services">
          <Trans ns="term" i18nKey="section-13-suspension-of-paid-services" />
        </Title>
        <Trans
          ns="term"
          i18nKey="if-the-user-falls-under-any-of-the-following-items-we-can-stop-the-use-of-the-paid-service-by-the-user"
        />
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="1-when-the-usage-period-of-the-predetermined-pay-service-has-passed"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="2-when-the-user-has-not-paid-the-usage-fee"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-14-prohibited-matters">
          <Trans ns="term" i18nKey="section-14-prohibited-matters" />
        </Title>
        <Trans
          ns="term"
          i18nKey="when-using-this-service-the-user-shall-not-act-in-any-of-the-following-items"
        />
        <ol>
          <li>
            <Trans
              ns="term"
              i18nKey="1-acts-that-infringe-other-users-of-this-service-third-parties-or-our-intellectual-property-rights-copyright-design-right-patent-right-utility-model-right-trademark-right-know-how-etc-or-actions-that-may-result"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="2-acts-that-infringe-other-users-of-this-service-third-parties-or-our-companys-property-credit-honor-privacy-or-portrait-rights-or-acts-that-may-lead-to-such-acts"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="3-act-of-transmitting-information-etc-to-the-effect-that-it-harms-the-body-life-freedom-honor-property-etc-of-other-users-of-this-service-or-a-third-party"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="4-acts-that-cause-or-may-cause-disadvantages-to-other-users-of-this-service-third-parties-or-our-company"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="5-providing-information-that-violates-public-order-and-morals-to-other-users-of-this-service-or-third-parties"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="6-acts-that-violate-the-law-criminal-acts-or-acts-that-may-cause-these-or-acts-that-assist-or-solicit-them"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="7-acts-that-interfere-with-the-operation-of-this-service-and-other-services-provided-by-our-company-or-acts-that-damage-the-trust-and-reputation-of-our-company"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="8-using-or-providing-harmful-programs-codes-or-files-such-as-computer-viruses-through-or-in-connection-with-this-service"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="9-act-of-sending-receiving-or-displaying-information-by-impersonating-another-person"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="10-sending-e-mails-comments-trackbacks-etc-against-a-large-number-of-unspecified-people-indiscriminately-or-in-large-numbers-using-our-service-or-sending-a-large-number-without-prior-approval-e-mail-information-distribution-act-to-the-destination"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="11-unauthorized-use-transfer-or-loan-of-ip-address-account-login-id-password-and-email-address"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="12-an-act-of-providing-or-reselling-or-reselling-a-service-similar-to-the-service-specified-in-this-service-to-a-third-party-regardless-of-whether-it-is-paid-or-free-of-charge-by-using-this-service"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="13-act-of-making-the-terminal-unable-to-receive-manufacturer-support-due-to-modification-of-the-operating-system-or-modification-of-the-terminal"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="14-unauthorized-access-to-other-users-of-this-service-third-parties-our-servers-or-other-computers"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="15-acts-that-significantly-load-the-server-or-network"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="16-acts-of-using-or-misusing-bugs-and-other-defects-in-programs"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="17-act-of-registering-false-information-in-our-company"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="18-any-act-that-violates-or-may-violate-any-provision-of-these-terms"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="19-other-acts-that-we-judge-to-be-inappropriate"
            />
          </li>
        </ol>
      </Paragraph>
    </>
  );
}

export default React.memo(Chapter2);
