import React from 'react';
import { Layout, Row, Col } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import SEO from 'components/SEO';
import TermMenu from 'components/Term/TermMenu';
import TermHeader from 'components/Term/TermHeader';
import Header from 'components/Landing/Header';
import Chapter1 from './Chapter1';
import Chapter2 from './Chapter2';
import Chapter3 from './Chapter3';
import './index.less';

const { Content } = Layout;

export default function Term() {
  const { t } = useTranslation();

  return (
    <Layout id="term" className="term-layout">
      <SEO title={t('termOfService')} description={t('SEODescription')} />
      <Row type="flex" justify="center">
        <Col xs={24}>
          <Header key="header" style={{ backgroundColor: 'white' }} />
        </Col>
      </Row>
      <Content className="content-container">
        <Row style={{ flex: 1 }}>
          <Col flex="300px" id="term-left">
            <TermMenu />
          </Col>
          <Col flex="1" id="term-right">
            {[
              {
                key: 'TermHeader',
                component: <TermHeader />,
              },
              {
                key: 'Chapter1',
                component: <Chapter1 />,
              },
              {
                key: 'Chapter2',
                component: <Chapter2 />,
              },
              {
                key: 'Chapter3',
                component: <Chapter3 />,
              },
            ].map(({ key, component }) => (
              <React.Fragment key={key}>{component}</React.Fragment>
            ))}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
