import React from 'react';
import { Typography } from 'antd';
import { Trans } from 'gatsby-plugin-react-i18next';

const { Title, Paragraph } = Typography;

function Chapter1() {
  return (
    <>
      <Title level={2} id="chapter-1-general-rules">
        <Trans ns="term" i18nKey="chapter-1-general-rules" />
      </Title>
      <Paragraph>
        <Title level={3} id="section-1-definition">
          <Trans ns="term" i18nKey="section-1-definition" />
        </Title>
        <Trans
          ns="term"
          i18nKey="in-this-agreement-the-terms-listed-in-the-following-items-shall-be-as-defined-in-each-item"
        />
        <ul>
          <li>
            <Trans ns="term" i18nKey="terms-moppium-terms-of-use" />
          </li>
          <li>
            <Trans ns="term" i18nKey="our-company-human-crest-co-ltd" />
          </li>
          <li>
            <Trans ns="term" i18nKey="this-service-moppium" />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title level={3} id="section-2-service-content">
          <Trans ns="term" i18nKey="section-2-service-content" />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="this-service-is-a-website-provided-by-our-company-which-provides-a-test-platform-in-the-cloud-environment-that-allows-users-to-automate-api-tests-through-a-browser-the-user-shall-be-able-to-monitor-and-view-the-test-execution-results-through-this-service"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="this-service-consists-of-a-paid-version-service-and-we-may-change-the-configuration-or-contents-of-the-service-arbitrarily"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="our-company-shall-be-able-to-change-the-specifications-of-this-service-without-notice-to-the-user"
            />
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Title
          level={3}
          id="section-3-application-and-modification-of-this-agreement"
        >
          <Trans
            ns="term"
            i18nKey="section-3-application-and-modification-of-this-agreement"
          />
        </Title>
        <ul>
          <li>
            <Trans
              ns="term"
              i18nKey="the-user-is-deemed-to-have-agreed-to-these-terms-while-continuing-to-use-the-service-if-you-do-not-agree-to-these-terms-you-cannot-use-this-service"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="the-provisions-we-make-available-to-you-from-time-to-time-online-or-by-other-means-form-part-of-these-terms"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="we-may-change-add-or-delete-these-terms-at-any-time-without-the-consent-of-the-user-in-this-case-the-changed-terms-will-take-effect-from-the-time-they-are-posted-on-the-website-of-the-service"
            />
          </li>
          <li>
            <Trans
              ns="term"
              i18nKey="if-there-is-a-change-in-these-terms-we-will-notify-you-by-a-method-that-we-consider-to-be-reasonable-if-the-user-continues-to-use-the-service-after-being-notified-that-changes-have-been-made-to-these-terms-it-is-considered-that-they-have-accepted-the-changes-to-these-terms"
            />
          </li>
        </ul>
      </Paragraph>
    </>
  );
}

export default React.memo(Chapter1);
